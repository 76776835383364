//SCSS
import '../scss/main.scss';
//Vendor-scripts
// import './vendor/slick.min.js'
import './vendor/jQuery.maskedinput.min.js'
// import './vendor/jquery-ui.min.js'
//Bootstrap
// import 'bootstrap';

//HTML
// import '../../index.html';

(function ( $ ) {
	
    $.fn.alterClass = function ( removals, additions ) {
        
        var self = this;
        
        if ( removals.indexOf( '*' ) === - 1) {
            // Use native jQuery methods if there is no wildcard matching
            self.removeClass( removals );
            return !additions ? self : self.addClass( additions );
        }
    
        var patt = new RegExp( '\\s' + 
                removals.
                    replace( /\*/g, '[A-Za-z0-9-_]+' ).
                    split( ' ' ).
                    join( '\\s|\\s' ) + 
                '\\s', 'g' );
    
        self.each( function ( i, it ) {
            var cn = ' ' + it.className + ' ';
            while ( patt.test( cn ) ) {
                cn = cn.replace( patt, ' ' );
            }
            it.className = $.trim( cn );
        });
    
        return !additions ? self : self.addClass( additions );
    };
    
    })( jQuery );

$(document).ready(function () {
    $('.test-btn').on('click', function(){
        
        $('.charity-form-section').fadeIn(300);
        setTimeout(() => {
            let windowHeight = $(window).height()
            let targetHeight = $('.charity-form__outer').innerHeight();
            console.log(windowHeight);
            console.log(targetHeight);
            $('.charity-form-section').addClass('_active');
            if(targetHeight>windowHeight) {
                $('.charity-form__outer').css('top', 0);
            } else {
                $('.charity-form__outer').css('top', windowHeight/2 - targetHeight/2)
            }
        }, 100);
        $('body').addClass('modal-open');
        if($(window).height() < $('.charity-form__outer').innerHeight()) {
            // $('.charity-form__overlay').css('height', $('.charity-form__outer').innerHeight())
        }
    });
    $('.charity-form__close').on('click', function(){
        if($('.charity-form-section').hasClass('_active')){
            $('.charity-form-section').removeClass('_active').fadeOut(300);
            $('body').removeClass('modal-open');
        }
    });

    $(document).click(function (e) {
        if ($(e.target).closest(".charity-form__outer").length) {
            if($('._stage1').hasClass('_disabled') && $(e.target).closest("._stage1").length) {
                console.log('click');
                $('._stage1').removeClass('_disabled');
                $('._stage2').fadeOut(300);
            }
            return;
        }
        if($('.charity-form-section').hasClass('_active')){
            $('.charity-form-section').removeClass('_active').fadeOut(300);
            $('body').removeClass('modal-open');
        }
    });

    $('.charity-form__back').on('click', function(){
        $('._stage1').removeClass('_disabled');
        $('._stage2').fadeOut(300);
    });

    $(document).on("change keyup input click", "input[name=custom-summ]", function() {
        if(this.value.match(/[^0-9]/g)){
            this.value = this.value.replace(/[^0-9]/g, "");
        };
    });
    $('[name=custom-summ]').on('click', function(){
        $('[name=summ]').each(function(){
            $(this).prop('checked', false);
        });
    });
    $('[name=custom-summ]').focusout(function(){
        if($(this).val()=='' || $(this).val()==' ') {
            $('[name=summ]').first().prop('checked', true);
        }
    });

    $('[name=summ]').on('change', function(){
        let summ = $(this).val();
        $( '.charity-form__outer' ).alterClass( '_*').addClass('_'+summ);

    });
    $('[name=custom-summ]').on('input', function(){
        let summ = $(this).val();
        console.log(summ);

        if(summ <= 300) {
            $( '.charity-form__outer' ).alterClass( '_*', '').addClass('_300');
        } else if(summ <= 500) {
            $( '.charity-form__outer' ).alterClass( '_*', '').addClass('_500');
        } else if(summ <= 700) {
            $( '.charity-form__outer' ).alterClass( '_*', '').addClass('_700');
        } else if(summ <= 1000) {
            $( '.charity-form__outer' ).alterClass( '_*', '').addClass('_1000');
        } else if(summ <= 5000) {
            $( '.charity-form__outer' ).alterClass( '_*', '').addClass('_5000');
        }
    });

    $('[data-stage]').on('click', function(e){
        e.preventDefault();
        let summ;
        $('._stage2').fadeIn(300);

        if($('[name=custom-summ]').val() != ''){
            summ = $('[name=custom-summ]').val();
        } else {
            summ = $('[name=summ]:checked').val();
        }
        $('.charity-form__average').text(summ+'₽');

        setTimeout(() => {
            $('._stage1').addClass('_disabled');
        }, 100);
    });

    $('[name=phone]').mask('+7 (999) 999 99 99');
});


$('.charity-form__wrapper').submit(function(){
    let summ;

    if($('[name=custom-summ]').val() != ''){
        summ = $('[name=custom-summ]').val();
    } else {
        summ = $('[name=summ]:checked').val();
    }

    $('[data-required]').each(function(){
        if($(this).val() == '' || $(this).val() == ' ') {
            $(this).addClass('_error');
        } else {
            $(this).removeClass('_error');
        }
    });

    if($('[data-required]').hasClass('_error')) {
        return false;
    }

    

    let formData = {
        'payment': $('[name=payment]').val(),
        'summ': summ,
        'comment': $('[name=comment]').val(),
        'name': $('[name=name]').val(),
        'surname': $('[name=surname]').val(),
        'phone': $('[name=phone]').val(),
        'mail': $('[name=mail]').val(),
        'paymentType': $('[name=payment-type]:checked').val(),
    };

    // $.ajax({
    //     url: '/index.php',         /* Куда пойдет запрос */
    //     method: 'get',             /* Метод передачи (post или get) */
    //     dataType: 'html',          /* Тип данных в ответе (xml, json, script, html). */
    //     data: {
    //         'payment': formData.payment,
    //         'summ': formData.summ,
    //         'comment': formData.comment,
    //         'name': formData.name,
    //         'surname': formData.surname,
    //         'phone': formData.phone,
    //         'mail': formData.mail,
    //         'paymentType': formData.paymentType,
    //     },     /* Параметры передаваемые в запросе. */
    //     success: function(data){   /* функция которая будет выполнена после успешного запроса.  */
    
    //         alert(data);            /* В переменной data содержится ответ от index.php. */
    
    //     }
    // });
    console.log(formData);
    return false;
});